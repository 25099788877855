import React, { useRef } from 'react'
import './map-qa.css'
import cross from '../../assets/img/clear-24px.svg'

export default function MapQa({ qaOpen, setQaOpen, showCloseIcon = true }) {
    const me = useRef(null)
    if (!qaOpen) return <div></div>

    return (
        <div ref={me} className='map-qa-popup' onClick={e => {
            if (e.target !== me.current) return;
            setQaOpen(false);
        }}>
            {showCloseIcon && <img className='close-map-qa-popup-cross-btn' onClick={_ => setQaOpen(false)} src={cross} alt='close map-qa-popup' />}
            <div className="map-qa-popup-wrapper">
                <div className='map-qa-popup-content'>
                {qaOpen.type == 'references' &&
                    <>
                        <h2>High, medium and low risk turbines</h2>
                        <p>
                            Evaluating the risk of lightning attachments is inherently difficult. To assist with the decision process on which actions to take we have divided the risk into three categories: high, medium and low. The categories are based on the accumulated probability that at least one lightning strike attached within the collection area of the turbine.
                        </p>
                        <h2>Marked for inspection</h2>
                        <p>
                            Turbines that are under investigation. This status is used when the risk has been acknowledge, and measures are being planned to mitigate the risk. When the turbine is in this state it is recommended to write updates to document what has been planned.
                            <p>
                                An example could be Inspection scheduled for August 2nd 2024.
                            </p>
                        </p>
                        <h2>Handled</h2>
                        <p>
                            Turbines where all the registered lightning risk has been mitigated or acknowledged and accepted.
                        </p>
                        <h2>Lightning sensor data</h2>
                        <p>
                        Turbines where a lightning sensor has registered lightning activity on the turbine. It is recommended to prioritize turbines, where both sensor measurements and meteorological data indicate lightning attachments.                        </p>
                    </>
                }
                {qaOpen.type == 'settings' &&
                    <>
                        <h2>Lightning layer</h2>
                        <p>
                            The ellipses on the map represent where a lightning strike has attached. They represent the 99th percentile confidence ellipses, which means there is a 99 % likelihood that the strikes was within the ellipse.
                        </p>
                        <p>
                            Small circles = precise measurement.
                        </p>
                        <p>
                            Larger circles = uncertain measurement.
                        </p>
                        <h2>Only show outside IEC</h2>
                        <p>
                            The peak current of each strike is evaluated against the IEC standard. When this layer is active only strikes with peak currents that fall outside of the certification limits will be shown.</p>

                        <p> This layer should only be used in conjunction with the lightning layer.
                        </p>
                        <h2>Strikes for nearest turbine</h2>
                        <p>
                            To determine which turbine it is most likely that a lightning strike has attached to we calculate a probability based on the overlap between the confidence ellipse of the lightning strike with the collection area of each turbine. One lightning strike can therefore contribute to the total risk level of several turbines. This layer will change that so, only the turbine which the strike was the closest to will be considered in the risk evaluation.</p>

                        <p>
                            An example could be that the calculated risk for one lightning strike was like this
                        </p>
                        <ul>
                            <li>
                                Turbine 01: 60%
                            </li>
                            <li>
                                Turbine 02: 30%
                            </li>
                            <li>
                                Turbine 03: 10%
                            </li>
                        </ul>
                        <p>

                            In this scenario the 30 & 10% risk will be removed from Turbine 02 and Turbine 03 respectively.
                        </p>

                        <p>  It is only recommended to use this when an inspection scope has to be narrowed down further than what the initial risk assessment would produce.
                        </p>
                        <h2>Collection area</h2>
                        <p>
                            The collection area of a turbine is defined in the IEC standard. In completely flat terrain it will be a circle with radius equal to three times the total height of the turbine. In LASSIE the collection area is adjusted using raytracing to account for more complex terrain in accordance with the specifications in the IEC standard.
                        </p>
                    </>
                }
                </div>
            </div>
        </div>

    );
};

